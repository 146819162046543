// Generated by Framer (5053712)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./IxaM6JZ8L-0.js";

const cycleOrder = ["SB1uxrMtz"];

const serializationHash = "framer-klbKC"

const variantClassNames = {SB1uxrMtz: "framer-v-n808rq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {IGeMnN5IH: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "SB1uxrMtz", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-n808rq", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"SB1uxrMtz"} ref={ref ?? ref1} style={{backgroundColor: "rgb(187, 221, 255)", ...style}}><Image background={{alt: "", fit: "fill"}} className={"framer-2r43uf"} layoutDependency={layoutDependency} layoutId={"oIcuVuAUW"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-klbKC.framer-i8xh02, .framer-klbKC .framer-i8xh02 { display: block; }", ".framer-klbKC.framer-n808rq { height: 75px; position: relative; width: 697px; }", ".framer-klbKC .framer-2r43uf { flex: none; height: 50px; left: 36px; overflow: visible; position: absolute; top: calc(54.666666666666686% - 50px / 2); width: 213px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 75
 * @framerIntrinsicWidth 697
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerIxaM6JZ8L: React.ComponentType<Props> = withCSS(Component, css, "framer-klbKC") as typeof Component;
export default FramerIxaM6JZ8L;

FramerIxaM6JZ8L.displayName = "Modal Services";

FramerIxaM6JZ8L.defaultProps = {height: 75, width: 697};

addFonts(FramerIxaM6JZ8L, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})